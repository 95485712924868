export const configcode = {
  debug: false, //enable console.log

  // ------------- Development Settings ----------------
  api: 'dev',
  //base saijai pos api url
  baseUrl: 'https://pos-dev.saijai-crm.com',

  //base saijai api blob url
  basePublic: 'https://public-dev.saijai-crm.com',

  //base saijai backoffice web url
  baseCrmUrl: 'https://backend-dev.saijai-crm.com',

  //base saijai api blob url
  bzbsBlobUrl: 'https://devstoragebuzzebees.blob.core.windows.net',
  themeVersion: 3,
  // ------------------ End Settings ---------------------
}
