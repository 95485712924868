import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    getReportPayDate({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/report/payment/v2/date/' + data)
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
  },
}
