import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { configcode } from '../../config/bzbsconfig'

Vue.use(Vuex)
export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    searchUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/users/search', data)
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
    getSalesList({ commit }, data = {}) {
      let params = {
        url: `${configcode.baseCrmUrl}/api/v1/commission/provider_sales_pos/type/${data.type}/goods/${data.id}`,
        method: 'GET',
      }
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `/api/v1/callApiCRM`,
          data: params,
        })
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
    postCalCommission({ commit }, data) {
      let params = {
        url: `${configcode.baseCrmUrl}/api/v1/commission/calculate_commission_pos`,
        method: 'POST',
        body: {
          data: data,
          receipt_code: data.receipt_code,
        },
      }

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `/api/v1/callApiCRM`,
          data: params,
        })
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
    getProviderSales({ commit }, payload) {
      let qs = encodeURIComponent(JSON.stringify(payload.data))
      let params = {
        url: `${configcode.baseCrmUrl}/api/v1/commission/provider_sales_pos/${payload.type}?user_course=${qs}`,
        method: 'GET',
      }
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `/api/v1/callApiCRM`,
          data: params,
        })
          .then(res => {
            if (res.status == 200) {
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
    getProviderSalesByOrder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/api/v1/commission/getSalesAndProvider/orderId/${payload.id}/type/${payload.type}`,
        })
          .then(res => {
            if (res.status == 200) {
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
  },
}
