import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
export default {
  state: {
    mini_course: '',
  },
  mutations: {
    SET_MINICOURSE(state, course) {
      state.mini_course = course
    },
  },
  getters: {
    getMiniCourse: state => state.mini_course,
  },
  actions: {
    getListCourse({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/course')
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
    getDataMiniCourse({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/course/mini/id/' + payload.id)
          .then(res => {
            console.log(res)
            if (res.data.message == 'success') {
              console.log(res)
              const order = res.data.data
              order.providerName = '-'
              order.salesName = '-'
              if (payload.providerSales.doctor_id) {
                order.providerName = payload.providerSales.doctor_fname + ' ' + payload.providerSales.doctor_lname
                order.providerSales = payload.providerSales
              }
              if ( payload.providerSales.sales_id) {
                order.salesName = payload.providerSales.sales_fname + ' ' + payload.providerSales.sales_lname
                order.providerSales = payload.providerSales
              }
              Object.assign(order, { price_pay: parseInt(order.remain) })

              const data = {
                orders: [order],
                total_pay: order.remain,
                total_price: order.remain,
                total_all: order.remain,
              }
              // console.log(this.arrayProduct)
              commit('SET_ORDER', data)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
    getUserCourse({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/course/user/id/${payload.id}`)
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              const order = res.data.data
              order.providerName = '-'
              order.salesName = '-'
              if (payload.providerSales.doctor_id) {
                order.providerName = payload.providerSales.doctor_fname + ' ' + payload.providerSales.doctor_lname
                order.providerSales = payload.providerSales
              }
              if ( payload.providerSales.sales_id) {
                order.salesName = payload.providerSales.sales_fname + ' ' + payload.providerSales.sales_lname
                order.providerSales = payload.providerSales
              }
              Object.assign(order, { price_pay: parseInt(order.remain) })
              const data = {
                orders: [order],
                total: order.remain,
                total_price: order.remain,
                total_pay: order.remain,
              }
              commit('SET_ORDER', data)
              resolve(order)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err)
          })
      })
    },
  },
}
