import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    createClinicAddress({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/address/clinic', data)
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },

    getClinicAddress({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/address/clinic')
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
    editClinicAddress({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/v1/address/clinic/id/' + data.id, data)
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },

    editUserAddress({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/sales/receipt/address/' + data.id, data)
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
  },
}
