<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs">
        <h4>{{ admin.fname }} {{ admin.lname }}</h4>
        <span class="mr-15">{{ admin.position }}</span>
        <!-- <v-icon>{{ icons.mdiChevronDown }}</v-icon> -->
      </div>
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4 mr-2" dot>
        <v-avatar size="40px">
          <v-img :src="admin.admin_picture"></v-img>
        </v-avatar>
      </v-badge>
    </template>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiChevronDown,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiChevronDown,
      },
    }
  },
  data() {
    return {
      admin: this.$store.getters.dataAdmin,
    }
  },
  methods: {
    getBranchLogo() {
      this.$store.dispatch('getBranchLogo').then(res => {
        console.log(res)
      })
    },
    getListMember() {},
  },
  mounted() {
    console.log(this.$store.getters)
    this.getBranchLogo()
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
