import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    getBranchLogo({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/branches/logo')
          .then(res => {
            if (res.status == 200) {
              resolve(res.data.data)
              localStorage.setItem('logo_branch', res.data.data)
              var xhr = new XMLHttpRequest()
              xhr.onload = function() {
                var reader = new FileReader()
                reader.onloadend = function() {
                  localStorage.setItem('base64_logo_pdf', reader.result)
                }
                reader.readAsDataURL(xhr.response)
              }
              xhr.open('GET', res.data.data)
              xhr.responseType = 'blob'
              xhr.send()
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },

    uploadLogoBill({ commit }, data) {
      console.log(data)
      var img = new FormData()
      img.append('logo_bill', data)
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/address/clinicLogo', img, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(res => {
            console.log(res)
            resolve(res)
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
            reject(err)
          })
      })
    },
    getClinicLogoByBranchId({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/address/clinicLogo/id/' + id)
          .then(res => {
            if (res.status == 200) {
              resolve(res.data.data)
              localStorage.setItem('logo_bill_small', res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
  },
}
