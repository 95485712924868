import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import '@mdi/font/css/materialdesignicons.css'
import '@/assets/css/custom.css'
import Vue from 'vue'
import VueExcelXlsx from 'vue-excel-xlsx'
import VueThailandAddress from 'vue-thailand-address'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import initAxios from './api/api'

Vue.config.productionTip = false

initAxios()
Vue.use(VueExcelXlsx)
Vue.use(VueThailandAddress)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
