import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
export default {
  state: {
    member: '',
  },
  mutations: {
    SET_MEMBER(state, member) {
      state.member = member
    },
  },
  getters: {
    getMember: state => state.member,
  },
  actions: {
    getDataMember({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/users/id/' + data)
          .then(res => {
            console.log(res)
            resolve(res.data.data)
            commit('SET_MEMBER', res.data.data)
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
    getListMember({ commit }, data) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        const response = fetch('https://api.saijai-crm.com/api/v1/search', {
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          },
        })
        console.log(response)
      })
    },
  },
}
