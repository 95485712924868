import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/loading',
    name: 'loadingpage',
    component: () => import('@/views/LoadingPage.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/paid-report',
    name: 'paid-report',
    component: () => import('@/components/PaidReport/PaidReport.vue'),
  },
  {
    path: '/openbill/detail',
    name: 'detailPayment',
    component: () => import('@/components/POS/DetailPOS.vue'),
  },
  {
    path: '/openbill/detail/:order_id',
    name: 'paySecondTime',
    component: () => import('@/components/POS/DetailPOS.vue'),
  },
  {
    path: '/openbill',
    name: 'openbill',
    component: () => import('@/views/Openbill/Openbill.vue'),
  },
  {
    path: '/openbill/billing',
    name: 'billing',
    component: () => import('@/components/Billing/Billing.vue'),
  },
  {
    path: '/openbill/minicourse',
    name: 'minicourse',
    component: () => import('@/components/POS/MiniCourse.vue'),
  },
  {
    path: '/openbill/customcourse',
    name: 'customcourse',
    component: () => import('@/components/POS/CustomCourse.vue'),
  },
  {
    path: '/list-order-daily',
    name: 'list-order-daily',
    component: () => import('@/components/Order/ListOrder.vue'),
  },
  {
    path: '/list-order-daily/billing/:id',
    name: 'billing-pdf',
    component: () => import('@/components/Billing/Billing.vue'),
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import('@/components/Logs/Logs.vue'),
  },
  {
    path: '/billing-form',
    name: 'billing-form',
    component: () => import('@/components/Billing/BillingForm.vue'),
  },

  {
    path: '/orderList',
    name: 'orderList',
    component: () => import('@/views/OrderList/OrderList.vue'),
  },
  {
    path: '/orderList/:id',
    name: 'order-detail',
    component: () => import('@/views/OrderList/OrderDetail.vue'),
  },
  {
    path: '/billingShow/:id',
    name: 'billing-no-token',
    component: () => import('@/components/Billing/BillingNoToken.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  // {
  //   path: '*',
  //   redirect: 'error-404',
  // },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
