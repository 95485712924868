import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { configcode } from '../../config/bzbsconfig'

Vue.use(Vuex)
export default {
  state: {
    orders: '',
    course_order: '',
    product_order: '',
  },
  mutations: {
    SET_ORDER(state, order) {
      state.orders = order
    },
    SET_COURSE_ORDER(state, course) {
      state.course_order = course
    },
    SET_PRODUCT_ORDER(state, product) {
      state.product_order = product
    },
  },
  getters: {
    dataOrders: state => state.orders,
    getProduct: state => state.product_order,
    getCourse: state => state.course_order,
  },
  actions: {
    setOrder({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_ORDER', data)
        resolve(data)
      })
    },
    getListOrder({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/sales/receipt/v2/' + data)
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },

    getReceiptOrderById({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/sales/receipt/id/' + id)
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
    postCancelCommission({ commit }, data) {
      let params = {
        url: `${configcode.baseCrmUrl}/api/v1/commission/delete/commission_transaction_pos/${data}`,
        method: 'DELETE',
      }
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `/api/v1/callApiCRM`,
          data: params,
        })
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.message)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
    cancelOrder({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/v1/sales/receipt/id/' + data.id, data)
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
    createOrder({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/sales', data)
          .then(res => {
            console.log(res)
            console.log('🚀 ~ file: sales.js:92 ~ returnnewPromise ~ res.data.data:', res.data.data)
            resolve(res.data.data)
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
            reject(err.response.data)
          })
      })
    },
    payOrder({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/sales/money/id/${data.id}`, data)
          .then(res => {
            commit('SET_ORDER', '')
            console.log(res)
            resolve(res.data.data)
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
            reject(err.response.data)
          })
      })
    },
    getOrderAgain({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/order/id/' + payload.id)
          .then(res => {
            console.log(res)
            if (res.data.message == 'success') {
              resolve(res.data.data)
              const order = res.data.data
              const order_list = []
              order.order_list.map(res => {
                res.providerName = '-'
                res.salesName = '-'
                if (payload.providerSales?.length) {
                  payload.providerSales.forEach(i => {
                    if (i.course_id == res.original_product_id || i.user_course_id == res.original_product_id) {
                      if (i.doctor_id) {
                        res.providerName = i.doctor_fname + ' ' + i.doctor_lname
                        res.providerSales = i
                      }
                      if (i.sales_id) {
                        res.salesName = i.sales_fname + ' ' + i.sales_lname
                        res.providerSales = i
                      }
                    }
                  })
                }
                if (res.remain != 0 && res.order_type != 'product') {
                  Object.assign(res, { price_pay: parseInt(res.remain) })
                  order_list.push(res)
                }
              })

              const data = {
                orders: order_list,
                total: order.remain,
                total_price: order.remain,
                total_pay: order.remain,
                data: order,
              }
              console.log('🚀 ~ file: sales.js:150 ~ returnnewPromise ~ data:', data)
              commit('SET_ORDER', data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
            reject(err.response.data)
          })
      })
    },
    getBillById({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/billing/id/' + id)
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
  },
}
