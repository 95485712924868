<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent" height="90">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <v-autocomplete
            style="border-radius:13px;"
            v-model="word"
            :items="userList"
            :filter="filter"
            item-text="fname"
            item-value="id"
            dense
            solo
            outlined
            placeholder="ค้นหาจากชื่อหรือเบอร์โทรศัพท์"
            :prepend-inner-icon="icons.mdiMagnify"
            class="app-bar-search flex-grow"
            hide-details
            hide-no-data
            @input.native="onSearch"
            @change="handleChange($event)"
          >
            <template v-slot:[`item`]="{ item }">
              <v-list-item-avatar>
                <img v-if="item.user_profile" :src="item.user_profile" @error="imageError" style="object-fit:cover" />
                <v-img v-else :src="require('@/assets/images/avatars/1.png')"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{ item.fname }} {{ item.lname }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.phone }} </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>

          <v-spacer></v-spacer>

          <!-- Right Content -->

          <!-- <v-btn icon small class="ms-3">
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6 mt-5">
        <slot></slot>
      </div>
    </v-main>

    <!-- <v-footer app inset color="transparent" absolute height="56" class="px-0">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span> &copy; 2021 </span>
          <span class="d-sm-inline d-none">
            <a
              href="https://themeselection.com/products/category/download-free-admin-templates/"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
              >Freebies</a
            >
            <a href="https://themeselection.com/blog/" target="_blank" class="me-6 text--secondary text-decoration-none"
              >Blog</a
            >
            <a
              href="https://github.com/themeselection/materio-vuetify-vuejs-admin-template-free/blob/main/LICENSE"
              target="_blank"
              class="text--secondary text-decoration-none"
              >MIT Licence</a
            >
          </span>
        </div>
      </div>
    </v-footer> -->
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'

export default {
  components: {
    VerticalNavMenu,
    AppBarUserMenu,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },
  data() {
    return {
      word: '',
      userList: [],
    }
  },
  methods: {
    imageError(event) {
      event.target.src = require('@/assets/images/avatars/1.png')
    },
    onSearch() {
      var data = {
        word: this.word,
      }
      console.log(data)
      this.$store.dispatch('searchUser', data).then(res => {
        console.log(res)
        this.userList = res
      })
    },
    filter(item, queryText, itemText) {
      const searchText = queryText.toLowerCase()

      const fname = item.fname.toLowerCase()
      const lname = item.lname.toLowerCase()
      const phone = item.phone.toLowerCase()

      return fname.indexOf(searchText) > -1 || lname.indexOf(searchText) > -1 || phone.indexOf(searchText) > -1
    },
    handleChange(id) {
      console.log(id)
      localStorage.setItem('user_id', id)
      location.replace('/openbill')
      // this.$router.push({ name: 'openbill' })
      // location.reload()
      this.word = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1640px;
  margin-left: auto;
  margin-right: auto;
}
</style>
<style scoped>
::v-deep .v-menu__content:not(.list-style) .v-list.v-list--dense .v-list-item {
  min-height: 3.5rem !important;
  height: 3.5rem !important;
}
</style>
