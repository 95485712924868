import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
export default {
  state: {},
  getters: {},
  actions: {
    getSignature({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/signature')
          .then(res => {
            console.log(res)
            resolve(res.data.data)
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
            reject(err.response.data)
          })
      })
    },
    createSignature({ commit }, data) {
      console.log(data)

      const formData = new FormData()
      formData.append('signature_name', data.name)
      if (data.img != '') {
        formData.append('signature_picture', data.img)
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/signature', formData)
          .then(res => {
            console.log(res)
            resolve(res.data.data)
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
            reject(err.response.data)
          })
      })
    },
  },
}
