import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
export default {
  state: {
    dataAdmin: '',
    token: '',
  },
  mutations: {
    SET_DATA_ADMIN(state, admin) {
      state.dataAdmin = admin
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
  },
  getters: {
    dataAdmin: state => state.dataAdmin,
    token: state => state.token,
  },
  actions: {
    checkUserLogin({ commit }, params) {
      var url = '/api/v1/authen/check/admin/' + params.username
      if (params.admin_id) {
        url = url + '/' + params.admin_id
      }
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              commit('SET_DATA_ADMIN', res.data.data.admin)
              commit('SET_TOKEN', res.data.data.token)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
    checkPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/authen/check/password', data)
          .then(res => {
            if (res.status == 200) {
              console.log(res)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(err.response.data)
          })
      })
    },
  },
}
