import Vue from 'vue'
import Vuex from 'vuex'

import utill from './modules/utill'
import login from './modules/login'
import course from './modules/course'
import product from './modules/product'
import member from './modules/member'
import sales from './modules/sales'
import logs from './modules/logs'
import orders from './modules/orders'
import branch from './modules/branch'
import address from './modules/address'
import search from './modules/search'
import report from './modules/report'
import signature from './modules/signature'

import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    utill,
    login,
    course,
    product,
    member,
    sales,
    logs,
    orders,
    branch,
    address,
    search,
    report,
    signature,
  },
  plugins: [vuexLocal.plugin],
})
