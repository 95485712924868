import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
export default {
  state: {
    ordersList: '',
  },
  mutations: {},
  getters: {},
  actions: {
    getListOrderBill({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/order')
          .then(res => {
            if (res.status == 200) {
              console.log(res.data.data)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            reject(err.response.data)
          })
      })
    },
    getOrderById({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/order/id/' + id)
          .then(res => {
            if (res.status == 200) {
              console.log(res.data.data)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            reject(err.response.data)
          })
      })
    },
    getListCourseCrm({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/course/list/' + payload.id)
          .then(res => {
            const listData = []
            let total_price = 0
            let total_pay = 0
            if (res.status == 200) {
              console.log(res.data.data)
              var arr = res.data.data
              arr.map((val, idx) => {
                Object.assign(val, {
                  value: 1,
                  discount_type: 1,
                  discount_price: '',
                  price_after_all: val.remain,
                  price_total: val.remain,
                  price_pay: val.remain,
                  discount_cal: 0,
                })
                val.providerName = '-'
                val.salesName = '-'
                if (payload.providerSales.length) {
                  payload.providerSales.forEach(i => {
                    if (i.user_course_id == val.id) {
                      if (i.doctor_id) {
                        val.providerName = i.doctor_fname + ' ' + i.doctor_lname
                        val.providerSales = i
                      }
                      if (i.sales_id) {
                        val.salesName = i.sales_fname + ' ' + i.sales_lname
                        val.providerSales = i
                      }
                    }
                  })
                }
                total_price += parseInt(val.remain)
                total_pay += parseInt(val.remain)
                listData.push(val)
              })
              console.log(total_price)
              console.log(total_price)

              const data = {
                orders: listData,
                total_pay: total_pay,
                total_price: total_price,
                total_all: total_pay,
              }
              // console.log(this.arrayProduct)
              commit('SET_ORDER', data)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            reject(err.response.data)
          })
      })
    },
    getListMiniCourseCrm({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/course/mini/list/' + payload.id)
          .then(res => {
            const listData = []
            let total_price = 0
            let total_pay = 0
            if (res.status == 200) {
              console.log(res.data.data)
              var arr = res.data.data
              arr.map((val, idx) => {
                console.log(val)
                Object.assign(val, {
                  value: 1,
                  discount_type: 1,
                  discount_price: '',
                  price_after_all: val.remain,
                  price_total: val.remain,
                  price_pay: val.remain,
                  discount_cal: 0,
                })
                val.providerName = '-'
                val.salesName = '-'
                if (payload.providerSales.length) {
                  payload.providerSales.forEach(i => {
                    if (i.user_course_mini_id == val.id) {
                      if (i.doctor_id) {
                        val.providerName = i.doctor_fname + ' ' + i.doctor_lname
                        val.providerSales = i
                      }
                      if (i.sales_id) {
                        val.salesName = i.sales_fname + ' ' + i.sales_lname
                        val.providerSales = i
                      }
                    }
                  })
                }
                total_price += parseInt(val.remain)
                total_pay += parseInt(val.remain)
                listData.push(val)
              })
              console.log(total_price)
              console.log(total_price)

              const data = {
                orders: listData,
                total_pay: total_pay,
                total_price: total_price,
                total_all: total_pay,
              }
              // console.log(this.arrayProduct)
              commit('SET_ORDER', data)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log(err)
            reject(err.response.data)
          })
      })
    },
  },
}
