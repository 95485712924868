import axios from 'axios'
import Vue from 'vue'
import store from '@/store'
// import config from '../../config'
import { configcode } from '../config/bzbsconfig'

export default function initAxios() {
  Vue.prototype.$http = axios
  
  // Sets the default url used by all of this axios instance's requests
  axios.defaults.baseURL = configcode.baseUrl
  const { token } = store.getters

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    axios.defaults.baseURL = configcode.baseUrl
    axios.defaults.headers.get.Accept = 'application/json'
  }

  axios.interceptors.request.use(
    request => {
      const { token } = store.getters
      request.headers.Authorization = `Bearer ${token}`

      // Do something before request is sent
      // NProgress.start();
      return request
    },
    error => {
      // Do something with request error
      // NProgress.done();
      console.log(error)

      return Promise.reject()
    },
  )

  // Add a response interceptor
  axios.interceptors.response.use(
    response => {
      // NProgress.done();
      // console.log(response)
      return response
    },
    error => {
      // Do something with response error
      // NProgress.done();
      console.log(error)

      return Promise.reject(error)
    },
  )
}
