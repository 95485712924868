import Vue from 'vue'
import Vuex from 'vuex'
import config from '../../../config'
import { configcode } from '@/config/bzbsconfig.js'
Vue.use(Vuex)
export default {
  state: {
    url: configcode.baseUrl,
  },
  getters: {
    url: state => state.url,
  },
}
